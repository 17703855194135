

.large-text {
  font-size: 2rem;
  margin: 1rem;
}

.small-text {
  font-size: 1rem;
  margin-top: 1rem;

}

.large_text {
  font-size: 1rem;
  text-decoration: underline;
  margin-top: 1rem;
  color: whitesmoke;
}

.small_text {
  font-size: 2rem;
  padding: 1rem;
  color: whitesmoke;
}

.contact_wrapper {
  background-color: #1c2237;
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 1rem;
  border-top: 5px solid purple;
}


.addressln2{
  margin-top: 5px;
}
.addressln3{
  margin-top: 5px;
}


.dark {
  color: #1c2237;
}

.darkBg {
  background-color: #1c2237;
}

.home__hero-subtitle {
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
}

.home__hero-img-wrapper {
  max-width: 555px;
}

.home__hero-img {
  max-width: 95%;
  margin-top: 0;
  margin-right: 0;
  margin-left: 10px;
  padding-right: 0;
  border-radius: 1rem;
}

img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

@media screen and (max-width: 991px) {
  .container {
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media screen and (max-width: 768px) {
  .home__hero-text-wrapper {
    padding-bottom: 65px;
  }

  .col {
    max-width: 100%;
    flex-basis: 100%;
  }

  .large_text {
    font-size: 1rem;
    text-decoration: underline;
    margin-top: 1rem;
    color: whitesmoke;
  }
  
  .small_text {
    font-size: 1.5rem;
    padding: 1rem;
    color: whitesmoke;
  }
}